export default {
  namespaced: true,
  state: {
    success: false,
    error: false,
    message: '',
  },
  mutations: {
    display(state, data) {
      if (state.message && data && !data.message) {
        state.message = data.message
        state.success = data.success
        state.error = data.error
        return true
      }
      if (!state.message && data && typeof data.message === 'string') {
        state.message = data.message
        state.success = data.success
        state.error = data.error
        return true
      }
      if (!state.message && data && data.message && data.message.length) {
        state.message = data.message[0]
        state.success = data.success
        state.error = data.error
      }
    },
  },
}
