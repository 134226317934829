import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, VBTogglePlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Inputmask from 'inputmask' // Long HQ
import ezIcon from '@/@core/components/ez-icon/ezIcon.vue'
import DateRangePicker from '@/@core/components/ez-daterange-picker/components/DateRangePicker.vue'
import '@/@core/scss/vue/libs/ez-daterange-picker.scss'

import vueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

import { i18n } from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import mixin from './mixin'
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/filter'

// Axios Mock Adapter
// import '@/@fake-db/db'

import { CoolSelectPlugin } from 'vue-cool-select'

// paste the line below only if you need "bootstrap" theme
import 'vue-cool-select/dist/themes/bootstrap.css'
// paste the line below only if you need "material-design" theme
import 'vue-cool-select/dist/themes/material-design.css'
// you can also import your theme

Vue.use(CoolSelectPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VBTogglePlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin(mixin)

Vue.component('vue-custom-scrollbar', vueCustomScrollbar)

Vue.component('ez-icon', ezIcon)
Vue.component('date-range-picker', DateRangePicker)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// ezgolf font
require('@core/assets/fonts/ezgolf/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// require jquery
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

// Long HQ - Import Input mask
Vue.directive('input-mask', {
  bind(el) {
    new Inputmask().mask(el)
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
