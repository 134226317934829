export default [
  {
    path: '/booking-retail',
    name: 'booking-retail',
    component: () => import('@/views/modules/booking-retail/BookingRetail.vue'),
    meta: {
      pageTitle: 'Đơn hàng bán lẻ',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Đơn hàng bán lẻ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-print-invoice/:id',
    name: 'booking-print-invoice',
    component: () => import('@/views/modules/booking/Invoice/IV01.vue'),
    meta: {
      pageTitle: 'In hóa đơn',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'In hóa đơn',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-print-list-invoice/:id',
    name: 'booking-print-list-invoice',
    component: () => import('@/views/modules/booking/Invoice/IV04.vue'),
    meta: {
      pageTitle: 'In danh sách hóa đơn',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'In danh sách hóa đơn',
          active: true,
        },
      ],
    },
  }
]
