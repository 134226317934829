/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */

export const requestOptions = {
  postBody(body) {
    return {
      method: 'POST',
      ...headers(),
      body: JSON.stringify({ ...body, ...auth() }),
    }
  },
  postBodyLogin(body) {
    return {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json',
      // },
      ...headers(),
      body: JSON.stringify(body),
    }
  },
  postBodyGetAuth(body) {
    return {
      method: 'POST',
      ...headers(),
      body: JSON.stringify({ ...body, ...authLogin() }),
    }
  },
}
function auth() {
  const userData = JSON.parse(localStorage.getItem('userGolfData'))
  return {
    Lang: localStorage.getItem('systemLanguage') || '1000000',
    Token: (userData) ? userData.AccessToken : '',
  }
}

function authLogin() {
  return {
    Lang: localStorage.getItem('systemLanguage') || '1000000',
    Token: JSON.parse(localStorage.getItem('foToken')) || '',
  }
}

function headers() {
  const currentUser = JSON.parse(localStorage.getItem('currentUser')) || {}
  const authHeader = currentUser.token
    ? { Authorization: `Bearer ${currentUser.token}` }
    : {}
  return {
    headers: {
      ...authHeader,
      'Content-Type': 'application/json',
    },
  }
}
