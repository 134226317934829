/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { i18n } from '@/libs/i18n'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export function handleResponse(response) {
  return response.text().then(text => {
    // console.log('res', response.status)
    const data = text && JSON.parse(text)

    if ([200].indexOf(response.status) === -1) {
      if ([401, 403].indexOf(response.status) !== -1) {
        window.location.href = '/login'
      } else {
        // if ([400].indexOf(response.status) !== -1) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: data.title,
            icon: 'ezGolf-icon-x',
            variant: 'danger',
            timeout: 100000,
          },
        })
      }
    }

    if (data.Status != '200') {
      // Nếu token hết hạn, đẩy ra login, Token hết hạn response trả ra như 1 error bình thường, cần dựa vào field "Token" để phân biệt token hết hạn và lỗi API
      if (data.Messages.filter(x => x.Field == 'Token').length) {
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('foToken')
        localStorage.removeItem('userGolfData')
        localStorage.removeItem('currentSiteId')
        localStorage.removeItem('timeZone')
        localStorage.removeItem('timeZoneOffset')
        localStorage.removeItem('Permission')

        window.location.href = '/login'
      } else {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: data.Messages[0].Code,
            icon: 'ezGolf-icon-x',
            text: data.Messages[0].MessageText,
            variant: 'danger',
            timeout: 100000,
          },
        })
      }
    }

    return data
  })
}
