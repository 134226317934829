import dayjs from 'dayjs'
import {
  showResToast, convertUTC, settingScroll, formatPrice, inputMaskByCurrency, showToast, getUTC, toggleBodyScrollbar, convertLocalDateToTimeHotel,getCurrentDateUTC
} from '@/@core/utils/utils'

const quarterOfYear = require('dayjs/plugin/quarterOfYear')
const utc = require('dayjs/plugin/utc')

dayjs.extend(quarterOfYear)
dayjs.extend(utc)

const mixin = {
  data() {
    return {}
  },
  computed: {
    languageDate() {
      return {
        formatLocale: {
          months: [
            `${this.$t('TH1')}`,
            `${this.$t('TH2')}`,
            `${this.$t('TH3')}`,
            `${this.$t('TH4')}`,
            `${this.$t('TH5')}`,
            `${this.$t('TH6')}`,
            `${this.$t('TH7')}`,
            `${this.$t('TH8')}`,
            `${this.$t('TH9')}`,
            `${this.$t('TH10')}`,
            `${this.$t('TH11')}`,
            `${this.$t('TH12')}`,
          ],
          // MMM
          monthsShort: [
            `${this.$t('TH1')}`,
            `${this.$t('TH2')}`,
            `${this.$t('TH3')}`,
            `${this.$t('TH4')}`,
            `${this.$t('TH5')}`,
            `${this.$t('TH6')}`,
            `${this.$t('TH7')}`,
            `${this.$t('TH8')}`,
            `${this.$t('TH9')}`,
            `${this.$t('TH10')}`,
            `${this.$t('TH11')}`,
            `${this.$t('TH12')}`,
          ],
          // dddd
          weekdays: [
            `${this.$t('T8')}`,
            `${this.$t('T2')}`,
            `${this.$t('T3')}`,
            `${this.$t('T4')}`,
            `${this.$t('T5')}`,
            `${this.$t('T6')}`,
            `${this.$t('T7')}`,
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: [
            `${this.$t('T8')}`,
            `${this.$t('T2')}`,
            `${this.$t('T3')}`,
            `${this.$t('T4')}`,
            `${this.$t('T5')}`,
            `${this.$t('T6')}`,
            `${this.$t('T7')}`,
          ],
          // first day of week
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    showResToast,
    showToast,
    convertUTC,
    settingScroll,
    formatPrice,
    inputMaskByCurrency,
    getCurrentDateUTC,
    dayjs,
    getUTC,
    toggleBodyScrollbar,
    convertLocalDateToTimeHotel,
    fnCount(count) {
      return `(+ ${count})`
    },
    utcConvertToLocalTimeZone(date, format) {
      if (localStorage.getItem('Permission')) {
        const outputFormat = format || null
        const d = date ? new Date(date) : new Date()
        const day = d.toLocaleString(
          'en-US',
          { timeZone: localStorage.getItem('timeZone') },
        )
        return outputFormat ? dayjs(day).format(outputFormat) : dayjs(day)
      }
      return date
    },
    checkPermission(val) { // check quyền truy cập, truyền vào tên api
      const Permission = localStorage.getItem('Permission')
      if (!Permission || (Permission && Permission.length === 0) || (Permission && Permission.length > 0 && !Permission.includes(val))) {
        // this.showToast('error', 'golf_common_you_dont_have_access');
        return true
      }
      return true
    },
    checkPermissionOpenSideBar(key, name) {
      if (!key || (key && this.checkPermission(key))) {
        this.$root.$emit('bv::toggle::collapse', name)
      }
    },
    checkPermissionOpenModal(key, name) {
      if (!key || (key && this.checkPermission(key))) {
        this.$root.$emit('bv::show::modal', name)
      }
    },
    hasPermission(item) {
      const permission = JSON.parse(localStorage.getItem('Permission'))
      let tmp = true
      if (permission) {
        item.forEach(x => {
          if (!permission.includes(x)) {
            tmp = false
          }
        })
        return tmp
      }
      return tmp
    },
    hasPermissionParent(item) {
      const permission = JSON.parse(localStorage.getItem('Permission'))
      let tmp = false
      if (permission) {
        item.forEach(x => {
          if (permission.includes(x)) {
            tmp = true
          }
        })
      }
      return tmp
    },
  },
  watch: {},
}
export default mixin
