<style lang="scss">
.ez-toast {
  position: fixed;
  z-index: 99999;
  bottom: 24px;
  right: 12px;
  font-size: 14px;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
  &.error {
    background-color: #ff0000;
  }
  &.success {
    background-color: #0da313;
  }
  i {
    margin-right: 10px;
    color: #ffffff !important;
  }
  span {
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
  }
}
</style>
<template>
  <div>
    <div
      v-if="message"
      class="ez-toast text-center"
      :class="{ error: error, success: success }"
    >
      <template v-if="error">
        error
      </template>
      <template v-if="success">
        check
      </template>
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right: 12,
    }
  },
  computed: {
    message() {
      return this.$store.state.ezToast.message
    },
    error() {
      return this.$store.state.ezToast.error
    },
    success() {
      return this.$store.state.ezToast.success
    },
  },
  watch: {
    message(value) {
      if (value) {
        setTimeout(() => {
          const data = { message: '' }
          this.$store.commit('ezToast/display', data)
        }, 3000)
      }
    },
  },
  created() {
    // if (window.location.pathname == '/register') {
    //   this.left = 128
    // }
  },
}
</script>
