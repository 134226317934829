/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { commonServices } from '@/api/common-services'

Vue.use(VueI18n)

const locale = localStorage.getItem('systemLanguage') || '1000000' // default locale

export const i18n = new VueI18n({
  locale,
  fallbackLocale: locale,
  messages: {},
})

export async function loadLocaleMessages() {
  let listLanguages
  let messages
  await commonServices.getLanguages()
    .then(res => {
      if (res) {
        listLanguages = res.Data.LangCategory.filter(x => x.Active === true)
      }
    })

  await commonServices.getTranslate()
    .then(res => {
      messages = JSON.parse(res.Data)
    })

  listLanguages.forEach(y => {
    i18n.setLocaleMessage(y.LangId, messages[y.LangId])
  })
}
