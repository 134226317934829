/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  COMMON,
  LIST_LANGUAGE,
  GET_LANGUAGES,
  GET_COMPANY,
  GET_CURRENCY,
  GET_LIST_SERIVCE,
  GET_LIST_SPECIAL_DAY,
  ADD_NEW_SPECIAL_DAY,
  UPDATE_SPECIAL_DAY,
  DELETE_SPECIAL_DAY,
  GET_LIST_TRANSACTION_CODE,
  GET_DETAIL_SPECIAL_DAY,
  GET_LIST_SOURCE,
  LOGIN,
  GET_TOKEN,
  SWITCH_SITE,
  API_RCF00,
  API_RRO01,
  API_URO01,
  API_UAT01,
  API_RSVAR
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function login(data) {
  return fetch(ROOT + LOGIN, requestOptions.postBodyLogin(data))
    .then(handleResponse)
    .then(data => data)
}

function getToken(data) {
  return fetch(ROOT + GET_TOKEN, requestOptions.postBodyGetAuth(data))
    .then(handleResponse)
    .then(data => data)
}

function switchSite(data) {
  return fetch(ROOT + SWITCH_SITE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => {
      const { Permission } = data.Data.Site.find(e => e.InActive === false)
      localStorage.setItem('Permission', JSON.stringify(Permission))
      return data
    })
}

function getCommon(data) {
  return fetch(ROOT + COMMON, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getLanguages(data) {
  return fetch(ROOT + LIST_LANGUAGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getTranslate(data) {
  return fetch(ROOT + GET_LANGUAGES, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getCompany(data) {
  return fetch(ROOT + GET_COMPANY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getCurrency(data) {
  return fetch(ROOT + GET_CURRENCY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListService(data) {
  return fetch(ROOT + GET_LIST_SERIVCE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListSpecialDay(data) {
  return fetch(ROOT + GET_LIST_SPECIAL_DAY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewSpecialDay(data) {
  return fetch(ROOT + ADD_NEW_SPECIAL_DAY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailSpecialDay(data) {
  return fetch(ROOT + GET_DETAIL_SPECIAL_DAY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateSpecialDay(data) {
  return fetch(ROOT + UPDATE_SPECIAL_DAY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteSpecialDay(data) {
  return fetch(ROOT + DELETE_SPECIAL_DAY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListTransactionCode(data) {
  return fetch(ROOT + GET_LIST_TRANSACTION_CODE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListSource(data) {
  return fetch(ROOT + GET_LIST_SOURCE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}


function api_RCF00(data) {
  return fetch(ROOT + API_RCF00, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RRO01(data) {
  return fetch(ROOT + API_RRO01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_URO01(data) {
  return fetch(ROOT + API_URO01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UAT01(data) {
  return fetch(ROOT + API_UAT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RSVAR(data) {
  return fetch(ROOT + API_RSVAR, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
export const commonServices = {
  login,
  getToken,
  switchSite,
  getCommon,
  getLanguages,
  getTranslate,
  getCompany,
  getCurrency,
  getListService,
  getListSpecialDay,
  addNewSpecialDay,
  updateSpecialDay,
  getDetailSpecialDay,
  deleteSpecialDay,
  getListTransactionCode,
  getListSource,
  api_RCF00,
  api_RRO01,
  api_URO01,
  api_UAT01,
  api_RSVAR
}
