<template>
  <table class="table-condensed">
    <thead>
      <tr>
        <th
          class="prev available"
          tabindex="0"
          @click="prevMonthClick"
        >
          <span />
        </th>
        <th
          v-if="showDropdowns"
          :colspan="showWeekNumbers ? 6 : 5"
          class="month"
        >
          <div class="row">

            <v-select
              v-model="month"
              label="label"
              :options="months"
              :reduce="item => item.value + 1"
              style="min-width: 50px"
              :clearable="false"
              class="monthselect col"
            >
              <!-- <template #selected-option="{ CourseName, Color }">
                        <ez-icon
                          icon="ezGolf-icon-golf-course"
                          :color="Color"
                          :size="18"
                        />
                        {{ CourseName }}
                      </template> -->
              <!-- <template v-slot:option="item">
                <ez-icon
                  icon="ezGolf-icon-golf-course"
                  :size="18"
                  :color="item.label"
                />
                {{ item.CourseName }}
              </template> -->
            </v-select>

            <!-- <select
              v-model="month"
              class="monthselect col"
            >
              <option
                v-for="(m, idx) in months"
                :key="idx"
                :value="m.value + 1"
                :disabled="!m.enabled"
              >
                {{ m.label }}
              </option>
            </select> -->

            <!-- <v-select
              v-model="year"
              :options="years"
              :clearable="false"
              style="min-width: 95px"
              class="yearselect col"
              @blur="checkYear"
            /> -->

            <v-select
              v-model="year"
              :options="years"
              :clearable="false"
              style="min-width: 95px"
              class="yearselect col"
              @blur="checkYear"
            />

            <!-- <select
              ref="yearSelect"
              v-model="year"
              type="number"
              class="yearselect col"
              @blur="checkYear"
            >
              <option
                v-for="(m, idx) in years"
                :key="idx"
                :value="m"
              >
                {{ m }}
              </option>
            </select> -->

            <!-- <input
              ref="yearSelect"
              v-model="year"
              type="number"
              class="yearselect col"
              @blur="checkYear"
            > -->
          </div>
        </th>
        <th
          v-else
          :colspan="showWeekNumbers ? 6 : 5"
          class="month"
        >
          {{ monthName }} {{ year }}
        </th>
        <th
          class="next available"
          tabindex="0"
          @click="nextMonthClick"
        >
          <span />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th
          v-if="showWeekNumbers"
          class="week"
        >
          {{ locale.weekLabel }}
        </th>
        <th
          v-for="(weekDay, idx) in locale.daysOfWeek"
          :key="idx"
        >
          {{ weekDay }}
        </th>
      </tr>
      <tr
        v-for="(dateRow, index) in calendar"
        :key="index"
      >
        <td
          v-if="showWeekNumbers && (index%7 || index===0)"
          class="week"
        >
          {{ $dateUtil.weekNumber(dateRow[0]) }}
        </td>
        <td
          v-for="(date, idx) in dateRow"
          :key="idx"
          :class="dayClass(date)"
          :data-date="dayjs(date).utc().tz(timeZone).format()"
          @click="$emit('date-click', date)"
          @mouseover="$emit('hover-date', date)"
        >
          <slot
            name="date-slot"
            :date="date"
          >
            {{ dayjs(date).format('DD') }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import dateUtilMixin from './dateUtilMixin'

export default {
  name: 'Calendar',
  mixins: [dateUtilMixin],
  props: {
    yearStart: {
      type: Number,
      default: null,
    },
    yearEnd: {
      type: Number,
      default: null,
    },
    monthDate: Date,
    localeData: Object,
    start: String,
    end: String,
    timeZone: String,
    minDate: String,
    maxDate: String,
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: Function,
      default: null,
    },
  },
  data() {
    const currentMonthDate = this.monthDate || this.start || new Date()
    return {
      currentMonthDate,
      year_text: currentMonthDate.getFullYear(),
    }
  },
  computed: {
    monthName() {
      return this.locale.monthNames[this.currentMonthDate.getMonth()]
    },
    year: {
      get() {
        // return this.currentMonthDate.getFullYear()
        return this.year_text
      },
      set(value) {
        this.year_text = value
        const newDate = this.$dateUtil.validateDateRange(new Date(value, this.month, 1), this.minDate, this.maxDate)
        if (this.$dateUtil.isValidDate(newDate)) {
          this.$emit('change-month', {
            month: newDate.getMonth(),
            year: newDate.getFullYear(),
          })
        }
      },
    },
    month: {
      get() {
        // console.log('tinh lai')
        // console.log(this.currentMonthDate)
        // console.log(this.start)
        // alert('tính lại')
        return this.currentMonthDate.getMonth() + 1
      },
      set(value) {
        const newDate = this.$dateUtil.validateDateRange(new Date(this.year, value - 1, 1), this.minDate, this.maxDate)

        this.$emit('change-month', {
          month: newDate.getMonth() + 1,
          year: newDate.getFullYear(),
        })
      },
    },
    calendar() {
      const { month } = this
      const year = this.currentMonthDate.getFullYear()
      const firstDay = new Date(year, month - 1, 1)
      const lastMonth = this.$dateUtil.prevMonth(firstDay).getMonth() + 1
      const lastYear = this.$dateUtil.prevMonth(firstDay).getFullYear()
      const daysInLastMonth = new Date(lastYear, month - 1, 0).getDate()

      const dayOfWeek = firstDay.getDay()

      const calendar = []

      for (let i = 0; i < 6; i++) {
        calendar[i] = []
      }

      let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1
      if (startDay > daysInLastMonth) startDay -= 7

      if (dayOfWeek === this.locale.firstDay) startDay = daysInLastMonth - 6

      const curDate = new Date(lastYear, lastMonth - 1, startDay, 12, 0, 0)

      for (let i = 0, col = 0, row = 0; i < 6 * 7; i++, col++, curDate.setDate(curDate.getDate() + 1)) {
        if (i > 0 && col % 7 === 0) {
          col = 0
          row++
        }

        calendar[row][col] = this.dayjs(curDate.getTime()).utc().tz(this.timeZone).format()
      }
      return calendar
    },
    months() {
      return this.locale.monthNames.map((m, idx) => ({
        label: m,
        value: idx,
        enabled:
          (!this.maxDate || (this.maxDate >= new Date(this.year, idx, 1)))
          && (!this.minDate || (this.minDate <= new Date(this.year, idx + 1, 0))),
      }))
    },
    years() {
      const years = []
      if (this.yearStart && this.yearEnd) {
        for (let i = this.yearStart; i <= this.yearEnd; i++) {
          years.push(i)
        }
      } else {
        for (let i = this.currentMonthDate.getFullYear() - 100; i <= this.currentMonthDate.getFullYear() + 100; i++) {
          years.push(i)
        }
      }
      return years
    },
    locale() {
      return this.$dateUtil.localeData(this.localeData)
    },
  },
  watch: {
    monthDate(value) {
      if (this.currentMonthDate.getTime() !== value.getTime()) {
        this.changeMonthDate(value, false)
      }
    },
  },
  methods: {
    prevMonthClick() {
      this.changeMonthDate(this.$dateUtil.prevMonth(this.currentMonthDate))
    },
    nextMonthClick() {
      this.changeMonthDate(this.$dateUtil.nextMonth(this.currentMonthDate))
    },
    changeMonthDate(date, emit = true) {
      const year_month = this.$dateUtil.yearMonth(this.currentMonthDate)
      this.currentMonthDate = this.$dateUtil.validateDateRange(date, this.minDate, this.maxDate)
      // console.info(date, this.currentMonthDate)
      if (emit && year_month !== this.$dateUtil.yearMonth(this.currentMonthDate)) {
        this.$emit('change-month', {
          month: this.currentMonthDate.getMonth() + 1,
          year: this.currentMonthDate.getFullYear(),
        })
      }
      this.checkYear()
    },
    dayClass(date) {
      const classes = {
        off: this.dayjs(date).utc().tz(this.timeZone).month() + 1 !== this.month,
        weekend: this.dayjs(date).utc().tz(this.timeZone).day() === 6 || this.dayjs(date).utc().tz(this.timeZone).day() === 0,
        today: this.dayjs().utc().tz(this.timeZone).format('YYYY-MM-DD') == this.dayjs(date).utc().tz(this.timeZone).format('YYYY-MM-DD'),
        active: this.start && this.dayjs(this.start).tz(this.timeZone).format('YYYY-MM-DD') == this.dayjs(date).utc().tz(this.timeZone).format('YYYY-MM-DD') || this.end && this.dayjs(this.end).tz(this.timeZone).format('YYYY-MM-DD') == this.dayjs(date).utc().tz(this.timeZone).format('YYYY-MM-DD'),
        // 'in-range': dt >= start && dt <= end,
        'start-date': this.dayjs(this.start).tz(this.timeZone).format('YYYY-MM-DD') == this.dayjs(date).utc().tz(this.timeZone).format('YYYY-MM-DD'),
        'end-date': this.dayjs(this.end).tz(this.timeZone).format('YYYY-MM-DD') == this.dayjs(date).utc().tz(this.timeZone).format('YYYY-MM-DD'),
        disabled: (this.minDate && this.dayjs(date).utc().tz(this.timeZone).valueOf() < this.dayjs(this.minDate).utc().tz(this.timeZone).valueOf())
          || (this.maxDate && this.dayjs(date).utc().tz(this.timeZone).valueOf() > this.dayjs(this.maxDate).utc().tz(this.timeZone).valueOf()),
      }
      return classes
      // return this.dateFormat ? this.dateFormat(classes, date) : classes
    },
    checkYear() {
      if (this.$refs.yearSelect !== document.activeElement) {
        this.$nextTick(() => {
          this.year_text = this.monthDate.getFullYear()
        })
      }
    },
  },
}
</script>

<style lang="scss">
// th, td {
//   padding: 2px;
//   background-color: white;
// }

td.today {
  font-weight: bold;
}

td.disabled {
  pointer-events: none;
  background-color: #eee;
  border-radius: 0;
  opacity: 0.6;
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$carousel-control-color: #ccc !default;
$viewbox: '-2 -2 10 10';
$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='#{$viewbox}'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='#{$viewbox}'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

.fa {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
  fill: $carousel-control-color;
}

.prev, .next {
  &:hover {
    background-color: transparent !important;
  }

  .fa:hover {
    opacity: 0.6;
  }
}

.chevron-left {
  width: 16px;
  height: 16px;
  display: block;
  background-image: $carousel-control-prev-icon-bg;
}

.chevron-right {
  width: 16px;
  height: 16px;
  display: block;
  background-image: $carousel-control-next-icon-bg;
}

.yearselect {
  padding-right: 1px;
  border: none;
  appearance: menulist;
}

.monthselect {
  border: none;
}

.monthselect .vs__dropdown-menu, .yearselect .vs__dropdown-menu {
  max-height: 250px;
  width: 95px!important;
  min-width: 95px!important;
}
</style>
