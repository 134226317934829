export default [
  {
    path: '/list-payment',
    name: 'list-payment',
    component: () => import('@/views/modules/invoice/ListPayment/ListPayment.vue'),
  },
  {
    path: '/list-invoice',
    name: 'list-invoice',
    component: () => import('@/views/modules/invoice/ListInvoice/ListInvoice.vue'),
  },
]
