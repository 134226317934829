export default [
  // Course Setting
  {
    path: '/course-setting',
    name: 'course-setting',
    component: () => import('@/views/modules/course-setting/CourseSetting.vue'),
    meta: {
      pageTitle: 'Cấu hình sân',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Cấu hình sân',
          active: true,
        },
      ],
    },
  },
  // Course Setting
  {
    path: '/driving-range-setting',
    name: 'driving-range-setting',
    component: () => import('@/views/modules/driving-range/DrivingRange.vue'),
    meta: {
      pageTitle: 'Cấu hình sân tập',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Cấu hình sân tập',
          active: true,
        },
      ],
    },
  },
  {
    path: '/package-driving-range',
    name: 'package-driving-range',
    component: () => import('@/views/modules/package-driving-range/PackageDrivingRange.vue'),
    meta: {
      pageTitle: 'Cấu hình gói sân tập',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Cấu hình gói sân tập',
          active: true,
        },
      ],
    },
  },
  // Golf Class
  {
    path: '/golf-class',
    name: 'golf-class',
    component: () => import('@/views/modules/golf-class/GolfClass.vue'),
    meta: {
      pageTitle: 'Golf Class',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Golf Class',
          active: true,
        },
      ],
    },
  },
  // Rate Code
  {
    path: '/rate-code',
    name: 'rate-code',
    component: () => import('@/views/modules/rate-code/RateCode.vue'),
    meta: {
      pageTitle: 'Rate Code',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Rate Code',
          active: true,
        },
      ],
    },
  },
  // Package
  {
    path: '/package',
    name: 'package',
    component: () => import('@/views/modules/package-setting/PackageSetting.vue'),
    meta: {
      pageTitle: 'Package',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Package',
          active: true,
        },
      ],
    },
  },
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/modules/config/config.vue'),
    meta: {
      pageTitle: 'config',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'config',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configReport',
    name: 'config',
    component: () => import('@/views/modules/configReport/config.vue'),
    meta: {
      pageTitle: 'configReport',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'configReport',
          active: true,
        },
      ],
    },
  },
  {
    path: '/revenue-center',
    name: 'RevenueCenter',
    component: () => import('@/views/modules/RevenueCenter/GroupServiceSetting.vue'),
    meta: {
      pageTitle: 'Revenue Center',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Revenue Center',
          active: true,
        },
      ],
    },
  }
]
