import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n, loadLocaleMessages } from '@/libs/i18n'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'
import starter from './routes/starter'
import setting from './routes/setting'
import retail from './routes/retail'
import invoice from './routes/invoice'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/', redirect: { name: 'welcome' } },
        {
            path: '/ar/:component', beforeEnter(to, from) {
                if (from.fullPath == '/') {
                    location.href = '/ar/?com=' + to.params.component
                }
                else {
                    return false
                }
            }
        },
        ...pages,
        ...starter,
        ...setting,
        ...retail,
        ...invoice,
        // {
        //   path: '*',
        //   redirect: 'error-404',
        // },
    ],
})

router.beforeEach(async (to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'auth-login' })

        // If logged in => not authorized
        // return next({ name: 'misc-not-authorized' })
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        // const userData = getUserData()
        next({ name: 'tee-sheet' })
    }

    // Lấy ngôn ngữ từ API
    await loadLocaleMessages()

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
