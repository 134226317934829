export default {
  namespaced: true,
  state: {
    selectedTee: null,
    isOpenModalNoShow: false,
    bookingInfo: {
      groupName: '',
      courses: null,
      companies: null,
      source: null,
      sale: null,
      familyName: null,
      name: null,
      phoneNumber: null,
      email: null,
      cardNumber: null,
      national: null,
      note: null,
    },
    teeTimeInfo: [],
    teePickedInfo: [],
    fields: [],
    items: [],
    agentInfo: {
      ClientId: null,
      SourceId: null,
    },
    golfBallTrack: [],
  },
  getters: {
  },
  mutations: {
    SELECTED_TEE(state, val) {
      state.selectedTee = val
    },
    SELECTED_MODAL_NOSHOW(state, payload) {
      state.isOpenModalNoShow = payload
    },
    SET_TEE_TIME_INFO(state, payload) {
      state.teeTimeInfo = payload
    },
    SET_BOOKING_INFO(state, payload) {
      state.bookingInfo = payload
    },
    SET_TEE_PICKED_INFO(state, payload) {
      state.teePickedInfo = payload
    },
    SET_FIELDS_TABLE_BOOKING(state, payload) {
      state.fields = payload
    },
    SET_ITEMS_TABLE_BOOKING(state, payload) {
      state.items = payload
    },
    SET_ITEMS_AGENT_INFO(state, payload) {
      state.agentInfo = payload
    },
    SET_GOLF_BALL_TRACK(state, payload) {
      state.golfBallTrack = payload
    },
  },
  actions: {},
}
