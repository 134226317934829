import {
  reactive, getCurrentInstance, watch, toRefs,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export function ezToast(status, message, isReload = true) {
  let error = true
  let success = false
  if (status) {
    error = false
    success = true
  }
  store.commit('ezToast/display', {
    message,
    error,
    success,
  })
  if (success && isReload) {
    setTimeout(() => {
      location.reload()
    }, 1000)
  }
}

export function formatPrice(value) {
  if (isNaN(value) || value == null) return
  return value.toLocaleString(undefined, { maximumFractionDigits: 4 })
}

export function convertUTC(value, format) {
  if (value) {
    if (value.search(/[^.]*$/) === 0) {
      // Không có chữ Z thì mới add Z
      if (value.search(/[^Z]*$/) === 0) {
        value += 'Z'
      }
    } else {
    // Nếu không tìm thấy Z mới add thêm Z
    // eslint-disable-next-line no-lonely-if
      if (value.search(/[^Z]*$/) === 0) {
        value = value.replace(/[^.]*$/, 'ABC').replace('.ABC', 'Z')
      }
    }

    const timeZone = localStorage.getItem('timeZone')

    if (format) {
    // return this.dayjs(value).utc().tz(timeZone).format()

      return dayjs(value).tz(timeZone).format(format)
    }
  }
  // return dayjs(date).toDate()
}

export function convertLocalDateToTimeHotel(value, format) {
  if (value) {
    // // console.log(value)
    // if (value.search(/[^.]*$/) === 0) {
    //   // Không có chữ Z thì mới add Z
    //   if (value.search(/[^Z]*$/) === 0) {
    //     value += 'Z'
    //   }
    // }

    const timeZone = localStorage.getItem('timeZone')

    if (format) {
    // return this.dayjs(value).utc().tz(timeZone).format()

      return dayjs(value).tz(timeZone).format(format)
    }
    return dayjs(value).tz(timeZone)
  }
  // return dayjs(date).toDate()
}
export function getCurrentDateUTC() {
  const timeZone = localStorage.getItem('timeZone')
  let date
  const offset = this.dayjs().utc().tz(timeZone)
      .utcOffset()
  date = this.dayjs().utc().tz(timeZone)
    .add(-(offset / 60), 'hour')
    .format('YYYY-MM-DDTHH:mm:ss')
  return `${date}Z`
}
export function getUTC(type, unit, day, m) {
  const minutes = m || 0
  const timeZone = localStorage.getItem('timeZone')
  let date
  if (type == 'start') {
    // Lệch bao nhiêu phút với giờ UTC 0
    const offset = this.dayjs().utc().tz(timeZone).startOf(unit)
      .utcOffset()

    // Chuyển sang giờ UTC 0
    if (day) {
      date = this.dayjs().utc().tz(timeZone).startOf(unit)
        .add(-(offset / 60), 'hour')
        .add(day, 'day')
        .add(minutes, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    } else {
      date = this.dayjs().utc().tz(timeZone).startOf(unit)
        .add(-(offset / 60), 'hour')
        .add(minutes, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss')
    }
  }

  if (type == 'end') {
    // Lệch bao nhiêu phút với giờ UTC 0
    const offset = this.dayjs().utc().tz(timeZone).endOf(unit)
      .utcOffset()

    // Chuyển sang giờ UTC 0
    if (day) {
      date = this.dayjs().utc().tz(timeZone).endOf(unit)
        .add(-(offset / 60), 'hour')
        .add(day, 'day')
        .format('YYYY-MM-DDTHH:mm:ss')
    } else {
      date = this.dayjs().utc().tz(timeZone).endOf(unit)
        .add(-(offset / 60), 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')
    }
  }

  return `${date}Z`
}

export function showResToast(res, message) {
  if (res.Status == '200') {
    var check = false
    if(res.Messages.length > 0) {
      check = true
      var titleResponse = ''
      res.Messages.forEach(x => {
        titleResponse = titleResponse + x.MessageText  + '. <br/>'
      })
    }
    this.$toast({
      component: ToastificationContent,
      props: {
        title: (message) ||  check ? titleResponse : this.$t('golf_common_success'),
        icon: 'ezGolf-icon-check',
        variant: 'success',
      },
    })
  }
  // else {
  //   this.$toast({
  //     component: ToastificationContent,
  //     props: {
  //       title: this.$t('golf_common_error'),
  //       icon: 'ezGolf-icon-x',
  //       text: res.Messages[0].MessageText,
  //       variant: 'danger',
  //     },
  //   })
  // }
}

export function showToast(type, message) {
  if (type == 'success') {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'ezGolf-icon-check',
        variant: 'success',
      },
    })
  } else {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'ezGolf-icon-x',
        variant: 'danger',
      },
    })
  }
}

export function settingScroll() {
  return {
    suppressScrollY: false,
    suppressScrollX: false,
    wheelPropagation: false,
  }
}

export function inputMaskByCurrency(currencyCode) {
  const listCurrency = JSON.parse(localStorage.getItem('userData')).currency
  let scale = 0

  if (listCurrency.filter(x => x.CurrencyCode == currencyCode).length > 0) {
    scale = listCurrency.find(x => x.CurrencyCode == currencyCode).NoOfDec
  }

  const temp = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalScale: scale,
  }
  return temp
}

export function toggleBodyScrollbar(visible) {
  const body = document.getElementsByTagName('body')[0]
  if (visible) { body.classList.add('overflow-hidden') } else { body.classList.remove('overflow-hidden') }
}
