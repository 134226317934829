import { $themeConfig } from '@themeConfig'

const newLocal = false
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: localStorage.getItem('VerticalMenuCollapsed') === 'false' ? newLocal : true,
    childrenMenu: [],
    isShowChild: false,
    rootRoute: 0,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
      localStorage.setItem('VerticalMenuCollapsed', val)
    },
    UPDATE_CHILDREN_MENU(state, val) {
      state.childrenMenu = val
    },
    UPDATE_CHILDREN_SHOW(state, val) {
      state.isShowChild = val
    },
    UPDATE_ROOT_ROUTE(state, val) {
      state.rootRoute = val
    },
  },
  actions: {},
}
